<template>
<div style="width: 100%;height: 100%">
    <div style="width: 100%;background: #FFFFFF;">
        <div style="width: 1500px;height: 100px;display: flex;align-items: center;justify-content: space-between;margin: 0 auto;background: #FFFFFF" >
            <div style="display: flex;align-items: center;">
                <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/bai_log.png" style="height: 46px;width: auto;margin: 0 10px;"/>
                <div style="color: #031F88;font-size: 30px;font-weight: bold"> | 文件柜</div>

                <div style="width: 600px;background: #FFFFFF"></div>
            </div>
            <div style="display: flex;align-items: center;">
                    <img src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/index/notice.png" style="height: 22px;width: auto"/>

                    <img :src="user.headIco" style="height: 30px;width: 30px;margin: 0 10px 0 40px;border-radius: 50%;"/>

                    <span style="margin-right: 10px;font-size: 18px;">{{user.nickName}}</span>

                    <i class="el-icon-caret-bottom"></i>

            </div>
        </div>
    </div>
    <div style="width: 1500px;height: 50px;margin: 10px auto;">
       <div style="width: 720px;height: 50px;background: #FFFFFF;float: left" >
           <div v-for="(item,index) in menu" :key="index" @click="addmenu(item.id)">
                <div style="width: 102.85px;height: 100%;font-size: 20px;float: left;font-weight: bold" ><div :style="glide_id == item.id ? 'background: #2970FF; color: #FFFFFF; height: 39px;' : ''" style="display: flex; justify-content: center;cursor: pointer;padding-top: 11px;">{{item.name}}</div></div>
           </div>
       </div>
        <div style="float: left;width: 770px;height: 50px;background: #FFFFFF;margin-left: 10px;">
            <el-input placeholder="请输入关键词搜索" v-model="searchfor">
                <template slot="append" ><el-button @click="getMyFileCabinet" type="primary" style="background: #2970FF;color: #FFFFFF;height: 50px;"><i class="el-icon-search" style="font-size: 18px;"></i></el-button></template>
            </el-input>
        </div>
    </div>
    <div style="width: 1500px;background: #FFFFFF;min-height: 800px;margin: 0 auto;overflow:hidden;">
<!--        <div style="width: 72px;height: 21px;color: #999999;padding: 20px 40px;font-size: 18px;">附件名称</div>-->
        <el-table
            :data="myfileCabinet"
            style="width: 100%"
            stripe
        >
            <el-table-column  prop="date" label="附件名称" width="775">
                <template slot-scope="scope" >
                    <div style="width: 775px;font-size: 18px;display: flex;margin-left: 20px;">
                       <div style="float: left" v-if="scope.row.suffix == '.pdf'">
                           <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/pdf.svg">
                       </div>
                       <div style="float: left" v-if="scope.row.suffix == '.doc'">
                           <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/doc.svg">
                       </div>
                       <div style="float: left" v-if="scope.row.suffix == '.docx'">
                           <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/docx.svg">
                       </div>
                        <div style="float: left" v-if="scope.row.suffix == '.xls'">
                            <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/xls.svg">
                        </div>
                        <div style="float: left" v-if="scope.row.suffix == '.xlsx'">
                            <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/xlsx.svg">
                        </div>
                        <div style="float: left" v-if="scope.row.suffix == '.ppt'">
                            <svg t="1709091510323" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7596" width="40" height="54"><path d="M80.384 885.76H10.752V487.424h66.56V0h601.088c5.12 0 10.24 1.024 14.336 3.072l9.216 6.144 233.472 228.352c8.192 7.168 11.264 18.432 9.216 29.696v220.16h66.56v398.336h-66.56V1024H244.224c-81.92 0-150.528-59.392-163.84-138.24zM645.632 66.56H143.872v420.864h735.232V294.912H719.36c-19.456 0-37.888-7.168-52.224-21.504-14.336-13.312-21.504-31.744-21.504-51.2V66.56z m-496.64 819.2c12.288 41.984 51.2 71.68 96.256 71.68h634.88v-71.68H148.992z m563.2-774.144v110.592c0 2.048 1.024 3.072 2.048 4.096 1.024 1.024 3.072 2.048 5.12 2.048h112.64L712.192 111.616zM512.512 574.464c14.336-2.048 28.672 2.048 37.888 12.288 10.24 10.24 14.336 24.576 12.288 37.888 0 32.768-15.36 49.152-46.08 49.152h-33.792v-99.328H512.512zM372.224 685.056c15.36-16.384 23.552-38.912 22.528-61.44 1.024-21.504-5.12-43.008-19.456-59.392-15.36-16.384-37.888-24.576-60.416-23.552h-90.112v284.672h46.08V707.584h39.936c22.528 1.024 45.056-7.168 61.44-22.528z m211.968 0c15.36-16.384 23.552-38.912 22.528-61.44 1.024-21.504-5.12-43.008-19.456-59.392-15.36-16.384-37.888-24.576-60.416-23.552h-90.112v284.672h46.08V707.584H522.752c22.528 1.024 45.056-7.168 61.44-22.528z m225.28-106.496l1.024-38.912H629.248v38.912h66.56v245.76h46.08v-245.76H809.472z m-509.952-4.096c14.336-2.048 28.672 2.048 37.888 12.288 10.24 10.24 14.336 24.576 11.264 37.888 0 32.768-15.36 49.152-46.08 49.152h-33.792v-99.328h30.72z m0 0" fill="#FC8A5F" p-id="7597"></path></svg>
                        </div>
                        <div style="float: left" v-if="scope.row.suffix == '.pptx'">
                            <img class="fileformat" src="https://baigongbao.oss-cn-beijing.aliyuncs.com/assets/%E6%96%87%E4%BB%B6/pptx.svg">
                        </div>
                        <div style="float: left">
                           <div style="color: #666666;font-size: 18px;font-weight: bold;margin: 5px 20px;cursor: pointer">{{scope.row.name}}</div>
                           <div style="font-size: 16px;color: #999999;margin-left: 20px;float: left">{{scope.row.size}}MB</div>
                       </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="address" label="下载日期" sortable>
                <template slot-scope="scope">
                    <div style="color: #999999;font-size: 18px;">
                        {{scope.row.updateTime}}
                    </div>
                </template>
                <template>
                    <el-button type="info">信息按钮</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="address">
                <template slot-scope="scope">
                    <div style="float: left">
                        <el-button @click="download(scope.row.url,scope.row.name)"><i class="el-icon-upload" style="margin: 0 2px;font-size: 15px;"></i>本地下载</el-button>
                    </div>
                    <div style="float: left;margin-left: 20px;">
                        <el-button @click="preview(scope.row.url)" type="primary" style="background: #2970FF;"><i class="el-icon-folder-remove" style="margin: 0 2px;font-size: 15px;"></i>打开</el-button>
                    </div>
                    <div style="float: left;margin-left: 20px;">
                        <el-button @click="delFileCabinet(scope.row.id)" type="info" style="background: #E4E4E4;color:#666666;border: 1px solid #E4E4E4;"><i class="el-icon-delete-solid" style="margin: 0 2px;font-size: 15px;"></i>删除</el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!--    分页    -->
        <!--        <div style="height: 50px;text-align: center">-->
        <!--            <el-pagination background layout="prev, pager, next" :total="number" :page-size="5" @current-change="getMyFileCabinet" :current-page.sync="page"></el-pagination>-->
        <!--        </div>-->
<!--        <div v-if="fileContent" v-html="fileContent" style="width: 100%;height: 100px;overflow: hidden"></div>-->
<!--        <iframe ref="fileViewer" style="width: 100%; height: 500px;"></iframe>-->
    </div>
</div>
</template>
<script>
// import pdfjsLib from 'pdfjs-dist/build/pdf';
// import axios from "axios";
export default {
    components: {

    },

    computed: {
        user() {
            return this.$store.state.user
        },
    },
    data () {
        return {
            menu:[
                {name:'全部',id:'0'},
                {name:'doc',id:'1'},
                {name:'pdf',id:'2'},
                {name:'xls',id:'3'},
                {name:'ppt',id:'4'},
                {name:'zip/rar',id:'5'},
                {name:'其它',id:'6'},
            ],
            glide_id:'0',
            searchfor:'',
            myfileCabinet:[],
            page: 1,
            pageSize: 10,
            number: 0,
            files: [],
            fileContent: ''

        }
    },
    mounted:function(){
        this.getMyFileCabinet();
        this.$refs.fileViewer.onload = () => {
            this.fileViewerLoaded = true;
        };
    },
    methods:{
        addmenu(id){
            this.glide_id = id;
            this.getMyFileCabinet();
        },
        // 获取列表
        getMyFileCabinet(){
          let that = this;
            that.newApi.getMyFileCabinet({
                 like:that.searchfor,
                 suffix:that.glide_id,
            }).then((res)=>{
                if (res.isSuccess == 1){
                    that.myfileCabinet = res.data;
                    that.searchfor = '';
                }
            })
        },
        //  删除
        delFileCabinet(id){
          var that = this;
            that.newApi.delFileCabinet({
                id:id
            }).then((res)=>{
                if (res.isSuccess == 1){
                    that.utils.sus(res.data);
                    that.getMyFileCabinet()
                }
            })
        },
        download(url,name){
            this.utils.download(url,name)
        },
        preview(url) {
            window.open(url, '_blank');
        },

        // async getFileContent(url) {
        //     try {
        //         const response = await fetch(url);
        //         if (response.ok) {
        //             const buffer = await response.arrayBuffer();
        //             const decoder = new TextDecoder('utf-8');
        //             this.fileContent = decoder.decode(buffer);
        //         } else {
        //             console.error('Failed to fetch file content');
        //         }
        //     } catch (error) {
        //         console.error('Error fetching file content:', error);
        //     }
        // }
        // previewFile(url) {
        //     const fileUrl = url;
        //     const viewerUrl = `https://docs.google.com/viewer?url=${fileUrl}`;
        //
        //     this.$refs.fileViewer.src = viewerUrl;
        // }

        // async convertToPDF(url) {
        //     console.log(url)
        //     try {
        //         const response = await axios({
        //             url: url,
        //             method: 'post',
        //             responseType: 'arraybuffer',
        //             headers: {
        //                 'Content-Type': 'application/json',
        //             }
        //         });
        //
        //         const pdfUrl = response.data.data.url;
        //         window.open(pdfUrl, '_blank');
        //     } catch (error) {
        //         console.error('Error converting file to PDF:', error);
        //     }
        // },
        // preview(url) {
        //     const fileExtension = url.split('.').pop().toLowerCase();
        //
        //     switch (fileExtension) {
        //         case 'pdf':
        //             window.open(url, '_blank');
        //             break;
        //         case 'doc':
        //         case 'docx':
        //         case 'ppt':
        //         case 'pptx':
        //             this.convertToPDF(url);
        //             break;
        //         default:
        //             console.log('Unsupported file type'); // 不支持的文件类型，打印错误信息到控制台
        //     }
        // }


    }
}
</script>
<style scoped>
/deep/ .el-input__inner {
    height: 50px;
    border-radius: 4px 0 0 4px;
}
/deep/ .el-table th.el-table__cell>.cell {
    font-size: 18px;
    margin-left: 20px;
}
.fileformat{
    width: 40px;
    height: 54px;
}
</style>